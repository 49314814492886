/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

const ProductContext = React.createContext({
    totalPrice: 0,
    totalDelivery: 2,
    platforms: {
        desktop: true,
        mobile: false,
    },
    activeUsersSize: null,
    viewersSize: null,
    features: {
        textMessages: true,
        fileSharing: false,
        multiroom: false,
        screenSharing: false,
        poorInternet: false,
        screenRecording: false,
        backgroundBlur: false,
    },
    updateTypeSettings: () => {},
    updatePlatforms: () => {},
    updateFeatures: () => {},
    updateActiveUsersSize: () => {},
    updateViewersSize: () => {},
    reset: () => {},
});

export default ProductContext;
