/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React from 'react';

import './styles.scss';

const PageFooter = (props) => {
    const { children, id } = props;

    return (
        <div id={id} className="page-footer">
            {children}
        </div>
    );
};

export default PageFooter;
