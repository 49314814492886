export const activeUsersLabels = {
    XS: '1 - 5',
    S: '6 - 50',
};

export const viewersLabels = {
    XS: '1 - 100',
    S: '100 - 1000',
    L: '1000 - 10000',
    XL: 'Unlimited',
};
