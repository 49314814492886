/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react';
import { ReactComponent as TrembitLogo } from 'assets/logo.svg';
import Button from 'components/common/Button';
import Title from 'components/common/Title';
import { NavLink } from 'react-router-dom';
import ProductContext from '../../../../productContext';
import { activeUsersLabels, viewersLabels } from '../../../../consts/productTypeSettings';

const getActiveUsersLabel = (type) => {
    switch (type) {
        case 'xs':
            return activeUsersLabels.XS;
        case 's':
            return activeUsersLabels.S;
        default:
            return null;
    }
};

const getViewersLabel = (type) => {
    switch (type) {
        case 'xs':
            return viewersLabels.XS;
        case 's':
            return viewersLabels.S;
        case 'l':
            return viewersLabels.L;
        case 'xl':
            return viewersLabels.XL;
        default:
            return null;
    }
};

const Receipt = () => {
    const Product = useContext(ProductContext);

    const activeUsersAmount = getActiveUsersLabel(Product.activeUsersSize);
    const viewersAmount = getViewersLabel(Product.viewersSize);

    return (
        <div className="container">
            <div className="header receipt-header">
                <NavLink className="logo-link" to="/">
                    <TrembitLogo />
                </NavLink>
                <Title className="order-header" size="large">
                    Summary
                </Title>
                <NavLink className="contacts-link" to="/contacts">
                    <Button type="outlined">contacts</Button>
                </NavLink>
            </div>

            <div className="content">
                <div className="order-wrapper">
                    <div className="order-receipt">
                        <div style={{ gridArea: 'pt' }}>
                            <Title className="order-section-header" size="large">
                                Platforms
                            </Title>
                        </div>
                        <div style={{ gridArea: 'tt' }}>
                            <Title className="order-section-header" size="large">
                                Type
                            </Title>
                        </div>
                        <div style={{ gridArea: 'ft' }}>
                            <Title className="order-section-header" size="large">
                                Features
                            </Title>
                        </div>
                        <div style={{ gridArea: 'pd' }} className="order-section-list">
                            <ul>
                                {Product.platforms.desktop && <li>Desktop browsers</li>}
                                {Product.platforms.mobile && <li>Mobile browsers</li>}
                            </ul>
                        </div>
                        <div style={{ gridArea: 'td' }} className="order-section-list">
                            <ul>
                                {Product.activeUsersSize && <li>Groups for {activeUsersAmount} active users</li>}
                                {Product.viewersSize && <li>Streaming for {viewersAmount} viewers</li>}
                            </ul>
                        </div>
                        <div style={{ gridArea: 'fd' }} className="order-section-list">
                            <ul>
                                {Product.features.textMessages && <li>Text messages</li>}
                                {Product.features.fileSharing && <li>File sharing</li>}
                                {Product.features.screenRecording && <li>Screen recording</li>}
                                {Product.features.screenSharing && <li>Screen sharing</li>}
                                {Product.features.multiroom && <li>Multi-room connection</li>}
                                {Product.features.poorInternet && <li>Poor internet</li>}
                                {Product.features.backgroundBlur && <li>Background blur</li>}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Receipt;
