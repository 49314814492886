/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React, { useContext, useState } from 'react';
import './styles.scss';
import Title from 'components/common/Title';
import TextParagraph from 'components/common/TextParagraph';
import { ReactComponent as LinkedInLogo } from 'assets/linked-in-logo.svg';
import { useHistory } from 'react-router-dom';
import TextInput from '../../../../components/common/TextInput';
import Button from '../../../../components/common/Button';
import ProductContext from '../../../../productContext';
import useSendEmail from '../../../../components/common/hooks/useSendEmail';
import { LINK_INSTAGRAM, LINK_LINKED_IN } from '../../../../consts/linksConsts';
import { ReactComponent as InstagramLogo } from '../../../../assets/instagram-logo.svg';

const ContactSales = () => {
    const Product = useContext(ProductContext);
    const history = useHistory();
    const sendEmailRequest = useSendEmail();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [company, setCompany] = useState('');
    const [extra, setExtra] = useState('');
    const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);

    const platforms = Object.keys(Product.platforms)
        .filter((n) => !!Product.platforms[n])
        .join(', ');

    const features = Object.keys(Product.features)
        .filter((n) => !!Product.features[n])
        .join(', ');

    const sendEmail = async () => {
        await sendEmailRequest({
            type: '2',
            firstName,
            lastName,
            email,
            company,
            extra,
            order: {
                price: Product.totalPrice,
                delivery: Product.totalDelivery,
                platforms,
                features,
                activeUsersSize: Product.activeUsersSize,
                viewersSize: Product.viewersSize,
            },
        });

        setEmailHasBeenSent(true);
    };

    const clickHome = () => {
        Product.reset();
        setEmailHasBeenSent(false);
        history.push('/');
    };

    return (
        <div className="contacts-container">
            <div className="header">
                <Title size="large">Contact sales</Title>
            </div>

            <div className="content">
                <div className="form-wrapper">
                    {emailHasBeenSent ? (
                        <>
                            <Title size="large" className="success-title">
                                Your order has been successfully sent. <br />
                                We’ll contact you soon!
                            </Title>
                            <div className="welcome-button-wrapper">
                                <Button onClick={clickHome} type="outlined">
                                    Back to welcome page
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <TextParagraph>We will contact you shortly! If you have any design or other inputs please send it to us.</TextParagraph>
                            <form noValidate className="contact-order-form">
                                <TextInput onChange={setFirstName} placeHolder="First name" />
                                <TextInput onChange={setLastName} placeHolder="Last name" />
                                <TextInput onChange={setEmail} placeHolder="Email" />
                                <TextInput onChange={setCompany} placeHolder="Company name" />
                                <TextInput onChange={setExtra} placeHolder="Additional info" />
                            </form>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={sendEmail} type="fulfilled">
                                    Contact
                                </Button>
                            </div>
                        </>
                    )}
                </div>
                <div className="about-wrapper">
                    <div>
                        <Title size="large" className="highlighted-title">
                            Press about us
                        </Title>
                        <TextParagraph>
                            You can check our latest news or read articles about us, just click{' '}
                            <a href="/" style={{ color: '#759CFF' }}>
                                here
                            </a>
                            !
                        </TextParagraph>
                    </div>
                    <div>
                        <Title size="large" className="highlighted-title">
                            Our social media
                        </Title>
                        <div className="media-links">
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a href={LINK_INSTAGRAM} target="_blank">
                                <InstagramLogo />
                            </a>
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a href={LINK_LINKED_IN} target="_blank">
                                <LinkedInLogo />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactSales;
