/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import './styles.scss';
import useVisibilityObserver from '../hooks/useVisibilityObserver';

const PageContent = (props) => {
    const ref = useRef();
    const { children, id } = props;
    const history = useHistory();
    const [isVisible] = useVisibilityObserver(ref);

    useEffect(() => {
        if (isVisible) {
            history.push(`#${id}`);
        }
    }, [isVisible]);

    return (
        <div ref={ref} id={id} className="page-content">
            {children}
        </div>
    );
};

export default PageContent;
