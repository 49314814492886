/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React, { useContext, useEffect, useState } from 'react';
import Button from '../../../../components/common/Button';
import Title from '../../../../components/common/Title';
import ProductContext from '../../../../productContext';
import { activeUsersLabels, viewersLabels } from '../../../../consts/productTypeSettings';

const GROUPS_SUB_NAME = 'groups';
const STREAMING_SUB_NAME = 'steaming';

const TypeTab = (props) => {
    const { isActive } = props;
    const Product = useContext(ProductContext);
    const { activeUsersSize, viewersSize, updateActiveUsersSize, updateViewersSize } = Product;
    const [activeSubGrid, setActiveSubGrid] = useState(null);

    useEffect(() => {
        if (!isActive) {
            setActiveSubGrid(null);
        }
    }, [isActive]);

    const scrollToActiveSubGrid = () => {
        setTimeout(() => {
            document.querySelector('.sub-grid.opened').scrollIntoView({ behavior: 'smooth' });
        }, 300);
    };

    const groupButtonClick = () => {
        if (activeSubGrid !== GROUPS_SUB_NAME) {
            setActiveSubGrid(GROUPS_SUB_NAME);
            scrollToActiveSubGrid();
        } else {
            setActiveSubGrid(null);
        }
    };

    const streamingButtonClick = () => {
        if (activeSubGrid !== STREAMING_SUB_NAME) {
            setActiveSubGrid(STREAMING_SUB_NAME);
            scrollToActiveSubGrid();
        } else {
            setActiveSubGrid(null);
        }
    };

    const setActiveUsersSize = (type) => {
        if (activeUsersSize === type) {
            updateActiveUsersSize('xs');
        } else {
            updateActiveUsersSize(type);
        }
    };

    const setViewersSize = (type) => {
        if (viewersSize === type) {
            updateViewersSize(null);
        } else {
            updateViewersSize(type);
        }
    };

    return (
        <>
            {/** ******* FIRST LINE ******* */}
            <Title size="large" className="align-left">
                Select your
                <br />
                <span className="highlighted-title">type</span>
            </Title>
            <div className="tab-content-grid">
                <div>
                    <Button type="outlined" checked>
                        Peer-to-peer<sup>(included)</sup>
                    </Button>
                </div>
                <div className="choice-description">
                    <span className="greyed-text">Secured 1-to-1 option. Direct communication using WebRTC, avoiding Server side</span>
                </div>
                {/** ******* SECOND LINE ******* */}
                <div>
                    <Button type="outlined" checked={activeSubGrid === GROUPS_SUB_NAME} onClick={groupButtonClick}>
                        Group chat
                    </Button>
                </div>
                <div className="choice-description">
                    Multiple participants.
                    <br />
                    <span className="greyed-text">Best for conferences. Secured communication via encrypted server.</span>
                </div>
            </div>
            <div className={`tab-content-grid sub-grid grouped-buttons ${activeSubGrid === GROUPS_SUB_NAME ? 'opened' : 'closed'}`}>
                <div>
                    <Button checked={activeUsersSize === 'xs'} onClick={() => setActiveUsersSize('xs')} type="outlined">
                        {activeUsersLabels.XS}
                    </Button>
                </div>
                <div>
                    <Button checked={activeUsersSize === 's'} onClick={() => setActiveUsersSize('s')} type="outlined">
                        {activeUsersLabels.S}
                    </Button>
                </div>
            </div>
            <div className="tab-content-grid">
                {/** ******* THIRD LINE ******* */}
                <div>
                    <Button type="outlined" checked={activeSubGrid === STREAMING_SUB_NAME} onClick={streamingButtonClick}>
                        Streaming
                    </Button>
                </div>
                <div className="choice-description">
                    Viewers.
                    <br />
                    <span className="greyed-text">Best suits for Online ClassRooms, Webinars.</span>
                </div>
            </div>
            <div className={`tab-content-grid sub-grid grouped-buttons ${activeSubGrid === STREAMING_SUB_NAME ? 'opened' : 'closed'}`}>
                <div>
                    <Button checked={viewersSize === 'xs'} onClick={() => setViewersSize('xs')} type="outlined">
                        {viewersLabels.XS}
                    </Button>
                </div>
                <div>
                    <Button checked={viewersSize === 'l'} onClick={() => setViewersSize('l')} type="outlined">
                        {viewersLabels.L}
                    </Button>
                </div>
                <div>
                    <Button checked={viewersSize === 's'} onClick={() => setViewersSize('s')} type="outlined">
                        {viewersLabels.S}
                    </Button>
                </div>
                <div>
                    <Button checked={viewersSize === 'xl'} onClick={() => setViewersSize('xl')} type="outlined">
                        {viewersLabels.XL}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default TypeTab;
