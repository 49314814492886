/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react';
import './styles.scss';
import { NavLink, useLocation } from 'react-router-dom';
import PageContent from '../../components/common/PageContent';
import Receipt from './sections/Receipt';
import ContactSales from './sections/ContactSales';
import PageFooter from '../../components/common/PageFooter';
import ProductContext from '../../productContext';
import Button from '../../components/common/Button';
import useDeliveryFormat from '../../components/common/hooks/useDeliveryFormat';
import usePriceFormat from '../../components/common/hooks/usePriceFormat';

const Order = () => {
    const Product = useContext(ProductContext);
    const location = useLocation();
    const deliveryLabel = useDeliveryFormat(Product.totalDelivery);
    const priceLabel = usePriceFormat(Product.totalPrice);
    const contactSalesIsVisible = location.hash === '#contacts';

    return (
        <div className="main-wrapper">
            <PageContent id="order">
                <Receipt />
            </PageContent>

            <PageContent id="contacts">
                <ContactSales />
            </PageContent>

            <PageFooter>
                <div className="section-footer">
                    {!contactSalesIsVisible && (
                        <div className="prev-section-button">
                            <NavLink to="/configurator">
                                <Button type="outlined">Change configuration</Button>
                            </NavLink>
                        </div>
                    )}

                    <div className="estimate">
                        <span className="estimate-label">Total price</span>
                        <span className="estimate-value">{priceLabel}</span>
                    </div>

                    <div className="estimate">
                        <span className="estimate-label">Estimated delivery</span>
                        <span className="estimate-value">{deliveryLabel}</span>
                    </div>

                    {!contactSalesIsVisible && (
                        <div className="next-section-button">
                            <div className="mobile-prev-section-button">
                                <NavLink to="/configurator">
                                    <Button type="outlined">Back</Button>
                                </NavLink>
                            </div>
                            <a href="#contacts">
                                <Button type="fulfilled">Contact sales</Button>
                            </a>
                        </div>
                    )}
                </div>
            </PageFooter>
        </div>
    );
};

export default Order;
