/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './styles.scss';

const TextParagraph = (props) => {
    const { children } = props;
    return <p className="text-paragraph">{children}</p>;
};

export default TextParagraph;
