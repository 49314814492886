/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react';
import Button from '../../../../components/common/Button';
import Title from '../../../../components/common/Title';
import ProductContext from '../../../../productContext';

const featureTypes = {
    FILE_SHARING: 'File sharing',
    MULTI_ROOM: 'Multiroom connection',
    SCREEN_SHARING: 'Screen sharing',
    POOR_INTERNET: 'Poor Internet Pack',
    SCREEN_RECORDING: 'Screen recording',
    BLUR: 'Background blur',
};

const FeaturesTab = () => {
    const Product = useContext(ProductContext);
    return (
        <>
            <Title size="large" className="align-left">
                Select your
                <br />
                <span className="highlighted-title">features</span>
            </Title>
            <div className="tab-content-grid">
                <div>
                    <Button type="outlined" checked>
                        Text messages<sup>(included)</sup>
                    </Button>
                </div>
                <div className="choice-description" />
            </div>

            <br />
            <br />

            <div className="choice-description">Add features</div>

            <div className="tab-content-grid grouped-buttons">
                <div>
                    <Button
                        onClick={() => {
                            Product.updateFeatures({ fileSharing: !Product.features.fileSharing });
                        }}
                        checked={Product.features.fileSharing}
                        type="outlined"
                    >
                        {featureTypes.FILE_SHARING}
                    </Button>
                </div>
                <div>
                    <Button
                        onClick={() => {
                            Product.updateFeatures({ multiroom: !Product.features.multiroom });
                        }}
                        checked={Product.features.multiroom}
                        type="outlined"
                    >
                        {featureTypes.MULTI_ROOM}
                    </Button>
                </div>
                <div>
                    <Button
                        onClick={() => {
                            Product.updateFeatures({ screenSharing: !Product.features.screenSharing });
                        }}
                        checked={Product.features.screenSharing}
                        type="outlined"
                    >
                        {featureTypes.SCREEN_SHARING}
                    </Button>
                </div>
                <div>
                    <Button
                        onClick={() => {
                            Product.updateFeatures({ poorInternet: !Product.features.poorInternet });
                        }}
                        checked={Product.features.poorInternet}
                        type="outlined"
                    >
                        {featureTypes.POOR_INTERNET}
                    </Button>
                </div>
                <div>
                    <Button
                        onClick={() => {
                            Product.updateFeatures({ screenRecording: !Product.features.screenRecording });
                        }}
                        checked={Product.features.screenRecording}
                        type="outlined"
                    >
                        {featureTypes.SCREEN_RECORDING}
                    </Button>
                </div>
                <div>
                    <Button
                        onClick={() => {
                            Product.updateFeatures({ backgroundBlur: !Product.features.backgroundBlur });
                        }}
                        checked={Product.features.backgroundBlur}
                        type="outlined"
                    >
                        {featureTypes.BLUR}
                    </Button>
                </div>
            </div>
        </>
    );
};

export default FeaturesTab;
