/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React, { useContext } from 'react';
import Button from '../../../../components/common/Button';
import Title from '../../../../components/common/Title';
import ProductContext from '../../../../productContext';

const PlatformTab = () => {
    const Product = useContext(ProductContext);
    return (
        <>
            <Title size="large" className="align-left">
                Select your
                <br />
                <span className="highlighted-title">platforms</span>
            </Title>
            <div className="tab-content-grid">
                <div>
                    <Button type="outlined" checked>
                        Desktop<sup>(included)</sup>
                    </Button>
                </div>
                <div className="choice-description">
                    <span className="greyed-text">Google Chrome, Firefox, Safari are default on Desktop</span>
                </div>
                <div>
                    <Button
                        type="outlined"
                        checked={Product.platforms.mobile}
                        onClick={() => Product.updatePlatforms({ mobile: !Product.platforms.mobile })}
                    >
                        Mobile & Tablet
                    </Button>
                </div>
                <div className="choice-description">
                    <span className="greyed-text">Samsung internet, Google Chrome, Firefox, Safari on Android and IOS</span>
                </div>
            </div>
        </>
    );
};

export default PlatformTab;
