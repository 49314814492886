/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import { ReactComponent as TrembitLogo } from 'assets/logo.svg';
import Button from 'components/common/Button';
import Title from 'components/common/Title';
import TextParagraph from 'components/common/TextParagraph';
import { NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <div className="container welcome-screen">
            <div className="header">
                <NavLink to="/">
                    <TrembitLogo />
                </NavLink>
                <NavLink to="/contacts">
                    <Button type="outlined">contacts</Button>
                </NavLink>
            </div>

            <div className="content flexible">
                <Title size="large">
                    Custom <br /> Video Streaming
                </Title>
                <TextParagraph>
                    We build dedicated engineering teams for your business and develop <br /> custom video streaming platforms
                </TextParagraph>
                <NavLink to="/configurator">
                    <Button type="fulfilled">Customize</Button>
                </NavLink>
            </div>
        </div>
    );
};

export default Header;
