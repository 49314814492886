/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './styles.scss';

const Title = (props) => {
    const { size = 'medium', children, className = '' } = props;

    let result;
    switch (size) {
        case 'medium':
            result = <h2 className={`title medium ${className}`}>{children}</h2>;
            break;
        case 'large':
            result = <h1 className={`title large ${className}`}>{children}</h1>;
            break;
        case 'small':
            result = <h3 className={`title small ${className}`}>{children}</h3>;
            break;
        default:
            result = <h2 className={`title medium ${className}`}>{children}</h2>;
    }

    return result;
};

export default Title;
