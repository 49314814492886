/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import ProductContext from './index';

const estimateSettings = {
    platform: {
        desktop: {
            price: 7000,
            delivery: 14,
        },
        mobile: {
            price: 2000,
            delivery: 5,
        },
    },
    features: {
        textMessages: {
            price: 0,
            delivery: 0,
        },
        fileSharing: {
            price: 1000,
            delivery: 1,
        },
        screenSharing: {
            price: 1000,
            delivery: 1,
        },
        screenRecording: {
            price: 3000,
            delivery: 7,
        },
        multiroom: {
            price: 500,
            delivery: 7,
        },
        adaptiveBitrate: {
            price: 1500,
            delivery: 5,
        },
        polling: {
            price: 500,
            delivery: 7,
        },
    },
};

const ProductContainer = (props) => {
    const { children } = props;

    const [price, setPrice] = useState(0);
    const [delivery, setDelivery] = useState(2);

    const [activeUsersSize, setActiveUsersSize] = useState('xs');
    const [viewersSize, setViewersSize] = useState(null);
    const [platforms, setPlatforms] = useState({
        desktop: true,
        mobile: false,
    });

    const [features, setFeatures] = useState({
        textMessages: true,
        fileSharing: false,
        multiroom: false,
        screenSharing: false,
        poorInternet: false,
        screenRecording: false,
        backgroundBlur: false,
    });

    const updatePlatforms = (newState) => setPlatforms({ ...platforms, ...newState });
    const updateFeatures = (newState) => setFeatures({ ...features, ...newState });

    const reset = () => {
        updatePlatforms({
            desktop: true,
            mobile: false,
        });
        updateFeatures({
            textMessages: true,
            fileSharing: false,
            multiroom: false,
            screenSharing: false,
            poorInternet: false,
            screenRecording: false,
            backgroundBlur: false,
        });
        setActiveUsersSize('xs');
        setViewersSize(null);
    };

    useEffect(() => {
        let priceEstimate = estimateSettings.platform.desktop.price;
        let deliveryEstimate = estimateSettings.platform.desktop.delivery;

        if (platforms.mobile) {
            priceEstimate += estimateSettings.platform.mobile.price;
            deliveryEstimate += estimateSettings.platform.mobile.delivery;
        }

        if (features.fileSharing) {
            priceEstimate += estimateSettings.features.fileSharing.price;
            deliveryEstimate += estimateSettings.features.fileSharing.delivery;
        }

        if (features.multiroom) {
            priceEstimate += estimateSettings.features.multiroom.price;
            deliveryEstimate += estimateSettings.features.multiroom.delivery;
        }

        if (features.screenSharing) {
            priceEstimate += estimateSettings.features.screenSharing.price;
            deliveryEstimate += estimateSettings.features.screenSharing.delivery;
        }

        if (features.screenRecording) {
            priceEstimate += estimateSettings.features.screenRecording.price;
            deliveryEstimate += estimateSettings.features.screenRecording.delivery;
        }

        if (features.poorInternet) {
            priceEstimate += estimateSettings.features.adaptiveBitrate.price;
            deliveryEstimate += estimateSettings.features.adaptiveBitrate.delivery;
        }

        if (features.backgroundBlur) {
            priceEstimate += estimateSettings.features.polling.price;
            deliveryEstimate += estimateSettings.features.polling.delivery;
        }

        setPrice(priceEstimate);
        setDelivery(Math.round(deliveryEstimate));
    }, [platforms, features, activeUsersSize, viewersSize]);

    return (
        <ProductContext.Provider
            value={{
                totalPrice: price,
                totalDelivery: delivery,
                platforms,
                activeUsersSize,
                viewersSize,
                features,
                updatePlatforms,
                updateActiveUsersSize: setActiveUsersSize,
                updateViewersSize: setViewersSize,
                updateFeatures,
                reset,
            }}
        >
            {children}
        </ProductContext.Provider>
    );
};

export default ProductContainer;
