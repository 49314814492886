/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React, { useContext, useState } from 'react';
import './styles.scss';
import { useHistory } from 'react-router-dom';
import { ReactComponent as PhoneSvg } from 'assets/configuration-platform-phone.svg';
import Button from '../../components/common/Button';
import PlatformTab from './components/PlatformTab';
import TypeTab from './components/TypeTab';
import FeaturesTab from './components/FeaturesTab';
import ProductContext from '../../productContext';
import useDeliveryFormat from '../../components/common/hooks/useDeliveryFormat';
import usePriceFormat from '../../components/common/hooks/usePriceFormat';

const PLATFORM_TAB = 'platform';
const TYPE_TAB = 'type';
const FEATURES_TAB = 'features';

const Tabs = (props) => {
    const { children } = props;
    return <div className="tabs">{children}</div>;
};

const Tab = (props) => {
    const { children, selected = false, onClick = () => {} } = props;
    return (
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        <button type="button" onClick={onClick} className={`tab ${selected ? 'selected' : ''}`}>
            {children}
        </button>
    );
};

const Configurator = () => {
    const Product = useContext(ProductContext);
    const history = useHistory();
    const [activeTab, setActiveTab] = useState(PLATFORM_TAB);
    const deliveryLabel = useDeliveryFormat(Product.totalDelivery);
    const priceLabel = usePriceFormat(Product.totalPrice);
    let tabContentStyles = { transform: 'translateX(0vw)' };

    if (activeTab === TYPE_TAB) {
        tabContentStyles = { transform: 'translateX(-100vw)' };
    } else if (activeTab === FEATURES_TAB) {
        tabContentStyles = { transform: 'translateX(-200vw)' };
    }

    const goToNext = () => {
        switch (activeTab) {
            case PLATFORM_TAB:
                setActiveTab(TYPE_TAB);
                break;
            case TYPE_TAB:
                setActiveTab(FEATURES_TAB);
                break;
            case FEATURES_TAB:
                history.push('/order');
                break;
            default:
                break;
        }
    };

    const goBack = () => {
        switch (activeTab) {
            case PLATFORM_TAB:
                history.push('/');
                break;
            case TYPE_TAB:
                setActiveTab(PLATFORM_TAB);
                break;
            case FEATURES_TAB:
                setActiveTab(TYPE_TAB);
                break;
            default:
                break;
        }
    };
    return (
        <div className="configurator">
            <div className="section-header">
                <Tabs>
                    <Tab onClick={() => setActiveTab(PLATFORM_TAB)} selected={activeTab === PLATFORM_TAB}>
                        Platforms
                    </Tab>
                    <Tab onClick={() => setActiveTab(TYPE_TAB)} selected={activeTab === TYPE_TAB}>
                        Type
                    </Tab>
                    <Tab onClick={() => setActiveTab(FEATURES_TAB)} selected={activeTab === FEATURES_TAB}>
                        Features
                    </Tab>
                </Tabs>
            </div>

            <div className="content">
                <div style={tabContentStyles} className="tab-content-wrapper">
                    <section className="tab-content">
                        <div className="left-section">
                            <PlatformTab isActive={activeTab === PLATFORM_TAB} />
                        </div>
                        <div className="platform-left-side">
                            <PhoneSvg className={`mobile-picture ${Product.platforms.mobile ? 'is-visible' : 'is-hidden'}`} />
                        </div>
                    </section>

                    <section className="tab-content">
                        <div>
                            <div className="left-section">
                                <TypeTab isActive={activeTab === TYPE_TAB} />
                            </div>
                        </div>
                        <div className="type-left-side">
                            <PhoneSvg className="mobile-picture" />
                        </div>
                    </section>

                    <section className="tab-content">
                        <div className="left-section">
                            <FeaturesTab isActive={activeTab === FEATURES_TAB} />
                        </div>
                        <div className="features-left-side">
                            <PhoneSvg className="mobile-picture" />
                        </div>
                    </section>
                </div>
            </div>

            <div className="section-footer">
                <div className="prev-section-button">
                    <Button onClick={goBack} type="outlined">
                        Back
                    </Button>
                </div>
                <div className="estimate">
                    <span className="estimate-label">Estimated price</span>
                    <span className="estimate-value">{priceLabel}</span>
                </div>

                <div className="estimate">
                    <span className="estimate-label">Estimated delivery</span>
                    <span className="estimate-value">{deliveryLabel}</span>
                </div>

                <div className="next-section-button">
                    <div className="prev-mobile-button-wrapper">
                        <Button onClick={goBack} type="outlined">
                            Back
                        </Button>
                    </div>
                    <Button onClick={goToNext} type="fulfilled">
                        Next
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Configurator;
