/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './styles/index.scss';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import Contacts from './pages/Contacts';
import Order from './pages/Order';
import ProductContainer from './productContext/container';
import Configurator from './pages/Configurator';
import useVirtualHeightVariable from './components/common/hooks/useVirtualHeightVariable';

function App() {
    useVirtualHeightVariable(); // we need this for mobile vh correct support
    return (
        <div className="App">
            <BrowserRouter>
                <ProductContainer>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route path="/configurator" component={Configurator} />
                        <Route path="/contacts" component={Contacts} />
                        <Route path="/order" component={Order} />
                    </Switch>
                </ProductContainer>
            </BrowserRouter>
        </div>
    );
}

export default App;
