// Create our number formatter.
const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
});

const usePriceFormat = (totalPrice) => {
    return formatter.format(totalPrice).replace(/\.00/g, '');
};

export default usePriceFormat;
