/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './styles.scss';

const TextInput = (props) => {
    const { placeHolder = '', onChange = () => {} } = props;
    return <input onChange={(e) => onChange(e.currentTarget.value)} placeholder={placeHolder} className="form-input" type="text" />;
};

export default TextInput;
