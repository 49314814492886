const useSendEmail = () => {
    return (data) => {
        const res = fetch('https://trembit.com/vcp-landing.php', {
            method: 'POST',
            mode: 'no-cors',
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        });

        res.then(console.log).catch(console.error);
        return res;
    };
};

export default useSendEmail;
