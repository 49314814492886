import { useState, useEffect } from 'react';

const useVisibilityObserver = (elem) => {
    const [isVisible, setIsVisible] = useState(false);

    const observer = new IntersectionObserver(
        (entries) => {
            // isIntersecting is true when element and viewport are overlapping
            // isIntersecting is false when element and viewport don't overlap
            const isIntersecting = entries[0].isIntersecting === true;

            setIsVisible(isIntersecting);
        },
        { threshold: [0.9] }
    );

    useEffect(() => {
        const domElement = elem.current;
        observer.observe(domElement);
        return () => observer.unobserve(domElement);
    }, [elem]);

    return [isVisible];
};

export default useVisibilityObserver;
