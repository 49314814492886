/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import './styles.scss';
import Title from 'components/common/Title';
import TextParagraph from 'components/common/TextParagraph';
import { ReactComponent as TrembitLogo } from 'assets/logo.svg';
import { ReactComponent as LinkedInLogo } from 'assets/linked-in-logo.svg';
import { ReactComponent as InstagramLogo } from 'assets/instagram-logo.svg';
import { NavLink, useHistory } from 'react-router-dom';
import Button from '../../components/common/Button';
import TextInput from '../../components/common/TextInput';
import useSendEmail from '../../components/common/hooks/useSendEmail';
import { LINK_INSTAGRAM, LINK_LINKED_IN } from '../../consts/linksConsts';

const TEL = '+38 050 647 78 25';
const EMAIL = 'welcome@trembit.com';

const ContactSales = () => {
    const sendEmailRequest = useSendEmail();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [emailHasBeenSent, setEmailHasBeenSent] = useState(false);
    const history = useHistory();

    const sendEmail = async () => {
        await sendEmailRequest({
            type: '1',
            name,
            email,
            message,
        });
        setEmailHasBeenSent(true);
    };

    const clickHome = () => {
        setEmailHasBeenSent(false);
        history.push('/');
    };

    return (
        <div className="contacts-container">
            <div className="header">
                <Title size="large">Contact us</Title>
            </div>

            <div className="content">
                <div className="form-wrapper">
                    {emailHasBeenSent ? (
                        <>
                            <Title size="large" className="success-title">
                                Thanks, we’ll contact you soon!
                            </Title>
                            <div className="welcome-button-wrapper">
                                <Button onClick={clickHome} type="outlined">
                                    Back to welcome page
                                </Button>
                            </div>
                        </>
                    ) : (
                        <>
                            <TextParagraph>We will contact you shortly! If you have any design or other details please send it to us.</TextParagraph>
                            <form noValidate className="contact-order-form">
                                <TextInput onChange={setName} placeHolder="Name" />
                                <TextInput onChange={setEmail} placeHolder="Email" />
                                <TextInput onChange={setMessage} placeHolder="Please, type your message here" />
                            </form>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Button onClick={sendEmail} type="fulfilled">
                                    Contact
                                </Button>
                            </div>
                        </>
                    )}
                </div>
                <div className="about-wrapper">
                    <div>
                        <Title size="large" className="highlighted-title">
                            Press about us
                        </Title>
                        <TextParagraph>
                            You can check our latest news or read articles about us, just click{' '}
                            <a href="/" style={{ color: '#759CFF' }}>
                                here
                            </a>
                            !
                        </TextParagraph>
                    </div>
                    <div>
                        <Title size="large" className="highlighted-title">
                            Our social media
                        </Title>
                        <div className="media-links">
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a href={LINK_INSTAGRAM} target="_blank">
                                <InstagramLogo />
                            </a>
                            {/* eslint-disable-next-line react/jsx-no-target-blank */}
                            <a href={LINK_LINKED_IN} target="_blank">
                                <LinkedInLogo />
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div className="footer">
                <NavLink to="/">
                    <TrembitLogo />
                </NavLink>
                <div className="footer-contacts">
                    <a href={`mailto:${EMAIL}`} className="text-link">
                        {EMAIL}
                    </a>
                    <a href={`tel:${TEL}`} className="text-link">
                        {TEL}
                    </a>
                </div>
                <div className="rights-cell">©2020 Trembit. All rights reserved.</div>
            </div>
        </div>
    );
};

export default ContactSales;
