/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './styles.scss';
import PageContent from '../../components/common/PageContent';
import Header from './sections/Header';

const Home = () => {
    return (
        <div className="main-wrapper">
            <PageContent id="welcome">
                <Header />
            </PageContent>
        </div>
    );
};

export default Home;
