/* eslint react/prop-types: 0 */
// eslint-disable-next-line no-unused-vars
import React from 'react';
import './styles.scss';

const Button = (props) => {
    const { type = 'outlined', checked = false, children, onClick = () => {} } = props;
    const typeClass = type === 'fulfilled' ? 'fulfilled' : 'outlined';
    const checkedClass = checked ? 'checked' : '';
    return (
        <button type="button" className={`button ${typeClass} ${checkedClass}`} onClick={onClick}>
            {children}
        </button>
    );
};

export default Button;
